<template>
  <div>
    <v-data-iterator
      :items="orders"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      hide-default-footer
      :loading="isLoading"
      loading-text="Поиск броней"
    >
      <template v-slot:loading>
        <v-row class="justify-center">
          <v-fade-transition>
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-fade-transition>
        </v-row>
      </template>
      <template v-slot:default="props">
        <v-fade-transition>
          <v-row v-if="!isLoading">
            <v-col
              v-for="order in props.items"
              :key="order.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <order-card
                @callPopup="openDialog(order)"
                :order="order"
              ></order-card>
            </v-col>
          </v-row>
        </v-fade-transition>
      </template>
      <template v-if="!isLoading && numberOfPages != 0" v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-spacer></v-spacer>
          <span
            class="mr-4
            grey--text"
          >
            Страница {{ page + 1 }} из {{ numberOfPages }}
          </span>
          <v-btn
            fab
            color="primary"
            small
            :disabled="page === 0"
            @click="page--"
          >
            <v-icon color="black">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            small
            class="mx-3"
            :disabled="page === numberOfPages"
            @click="page++"
          >
            <v-icon color="black">mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
    <order-popup
      :is-new="false"
      :entity="currentEntity"
      v-model="dialogState"
    ></order-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import requestControl from "@/mixins/requestControl";
import orderCard from "@/components/management/orders/Card.vue";
import orderPopup from "@/components/management/orders/Popup.vue";
import { GET_ORDERS, SEARCH_ORDERS } from "../../../../store/const/orders";

export default {
  components: {
    orderCard,
    orderPopup
  },
  mixins: [requestControl],
  //  props:{
  //   viewType:{
  //     type: Number,
  //     required: true
  //   }
  // },
  data() {
    return {
      page: 0,
      itemsPerPage: 20,
      dialogState: false,
      currentEntity: {}
    };
  },
  computed: {
    ...mapGetters("Orders", {
      orders: "getOrders"
    }),
    isLoading() {
      return (
        this.loading(SEARCH_ORDERS) === "loading" ||
        this.loading(GET_ORDERS) === "loading"
      );
    },
    numberOfPages() {
      return Math.ceil(this.orders.length / this.itemsPerPage);
    }
  },
  methods: {
    openDialog(item) {
      this.currentEntity = item;
      this.dialogState = true;
    }
  }
};
</script>

<style>
.custom-width-button .v-btn__content {
  max-width: 100%;
}
</style>
