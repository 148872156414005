var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"fz-14 mb-0 py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.order.name)+" ")])],1)],1),_c('v-card-subtitle',[(_vm.order.agency)?_c('div',{staticClass:"subtitle-text"},[_vm._v(" Агентство: "+_vm._s(_vm.order.brand.client.agency.name)+" ")]):_vm._e(),_c('h6',{staticClass:"text--primary subtitle-text"},[_vm._v(" "+_vm._s(_vm.order.brand.name)+" / "+_vm._s(_vm.order.brand.client.name)+" ")])]),_c('v-divider'),_c('div',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_vm._v("Статус:")]),_c('status-chip',{attrs:{"status":_vm.order.status.id,"description":_vm.order.status.description}})],1)],1),_c('v-list',{staticStyle:{"align-self":"flex-end"},attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v("Перейти:")]),_c('v-list-item-content',{staticClass:"align-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text custom-width-button",attrs:{"small":"","target":"_blank","color":"primary","ripple":false,"to":{
                  name: 'updateReservation',
                  params: {
                    id: _vm.order.id
                  }
                }}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text-truncate",staticStyle:{"min-width":"30px","max-width":"100%"}},[_vm._v(_vm._s(_vm.order.name))]),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v("Открыть \""+_vm._s(_vm.order.name)+"\" в новой вкладке")])])],1)],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_vm._v("Опции:")]),_c('order-actions',{attrs:{"order":_vm.order},on:{"openPopup":function($event){return _vm.$emit('openPopup')}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }