<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="orders"
      :items-per-page="10"
      fixed-header
      :loading="isLoading"
      :disabled="isLoading"
      loader-height="6"
      loading-text="Загрузка списка... Пожалуйста подождите "
      class="elevation-0"
      :item-class="isDeleted"
      :footer-props="footerProps"
    >
      <template #[`header.options`]="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span> {{ header.text }} </span>
          <v-menu style="z-index:200" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...menu, ...tooltip }"
                    class="mx-1 dark--text"
                    fab
                    icon
                    small
                    ><h2>CC</h2></v-btn
                  >
                </template>
                <span>Пользовательские поля</span>
              </v-tooltip>
            </template>
            <v-list class="mb-2">
              <v-list-item
                v-show="!['Агентство', 'Клиент', 'Бренд'].includes(header.text)"
                v-for="header in allHeaders"
                :key="header.text"
              >
                <v-checkbox
                  v-model="header.visible"
                  :disabled="['Название', 'Опции'].includes(header.text)"
                  @change="
                    $store.commit('Orders/updateOrderHeaders', allHeaders)
                  "
                  :label="header.text"
                  color="primary"
                  hide-details
                ></v-checkbox>
                <!--CRUTCH: используем commit, т.к. computed не ловит изменеия пропса объекта внутри массива  -->
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template #[`item.name`]="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              v-on="on"
              color="accent"
              content="Нестандарт"
              overlap
              :value="item.exclusive"
            >
              <v-btn
                :ref="'idReservation' + item.id"
                v-bind="attrs"
                v-on="on"
                color="black"
                rounded
                outlined
                text
                class="black--text"
                :ripple="false"
                @click="
                  $store.commit('Reservation/setNavigationTree', {
                    ...$store.state.Reservation.navigationTree,
                    reservation: item.id
                  })
                "
                :to="{
                  name: 'updateReservation',
                  params: { id: item.id }
                }"
              >
                <span
                  class="text-truncate"
                  style="min-width: 100px;max-width: 262px;"
                  >{{ item.name }}</span
                >
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span
            >Открыть {{ item.exclusive ? "нестандартную" : "" }} бронь "{{
              item.name
            }}" в новой вкладке</span
          >
        </v-tooltip>
      </template>
      <template #[`item.status`]="{item}">
        <status-chip
          :status="item.status.id"
          :description="item.status.description"
        ></status-chip>
      </template>
      <template #[`item.created_at`]="{item}">
        <span v-if="item.created_at">{{
          new Date(item.created_at).toLocaleString()
        }}</span>
      </template>
      <template #[`item.deleted_at`]="{item}">
        <span v-if="item.deleted_at">{{
          new Date(item.deleted_at).toLocaleString()
        }}</span>
      </template>
      <template #[`item.updated_at`]="{item}">
        <span v-if="item.updated_at">{{
          new Date(item.updated_at).toLocaleString()
        }}</span>
      </template>
      <template #[`item.done_at`]="{item}">
        <span v-if="item.done_at">{{
          new Date(item.done_at).toLocaleString()
        }}</span>
      </template>
      <template #[`item.calc_deadline`]="{item}">
        <span v-if="item.calc_deadline">{{ item.calc_deadline }}</span>
        <span v-else>Не задано</span>
      </template>
      <template #[`item.keepers`]="{item}">
        <span v-if="item.keepers.length === 0"> -</span>
        <div class="d-flex align-center" v-else>
          <span>{{ item.keepers[0].name }}</span>
          <popover
            v-if="item.keepers.length > 1"
            title="Ответственные"
            :data="item"
            main-prop="keepers"
          ></popover>
        </div>
      </template>
      <template #[`item.options`]="{item}">
        <order-actions
          @openPopup="openDialog(item)"
          :order="item"
          @updateAction="$emit('updateAction', 'search')"
        ></order-actions>
      </template>
    </v-data-table>
    <order-popup
      :is-new="false"
      @updateAction="$emit('updateAction', 'search')"
      :entity="currentEntity"
      v-model="dialogState"
    ></order-popup>
  </v-card>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import requestControl from "@/mixins/requestControl";
import orderPopup from "@/components/management/orders/Popup.vue";
import statusChip from "@/components/common/statusChip";
import orderActions from "@/components/management/orders/Actions";
import {
  GET_ORDERS,
  GET_ORDERS_BY_BRAND,
  SEARCH_ORDERS
} from "../../../../store/const/orders";
import popover from "../../../management/users/Popover";

export default {
  name: "OrderListTable",
  components: {
    orderPopup,
    statusChip,
    orderActions,
    popover
  },
  mixins: [requestControl],
  props: {
    isNested: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentEntity: {},
      dialogState: false,
      footerProps: {
        "items-per-page-options": [5, 10, 20, 50, -1],
        "items-per-page-text": "Броней на странице:"
      }
    };
  },
  computed: {
    ...mapGetters("Orders", {
      orders: "getOrders",
      getAllHeaders: "getAllHeaders",
      headers: "getHeaders"
    }),
    isLoading() {
      return (
        this.loading(SEARCH_ORDERS) === "loading" ||
        this.loading(GET_ORDERS) === "loading" ||
        this.loading(GET_ORDERS_BY_BRAND) === "loading"
      );
    },
    allHeaders() {
      return this.getAllHeaders;
    }
  },

  methods: {
    isDeleted(order) {
      return order.deleted_at ? "grey" : "";
    },
    openDialog(item) {
      this.currentEntity = item;
      this.dialogState = true;
      this.$emit("onCurrentBrandPopupOpen", item.brand);
    }
  }
};
</script>
