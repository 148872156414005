<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col class="d-flex pa-1 justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!client.deleted_at"
            class="mr-1"
            @click.stop="$emit('brandDialog')"
            v-bind="attrs"
            v-on="on"
            fab
            icon
          >
            <v-icon dark>
              mdi-briefcase-plus
            </v-icon></v-btn
          >
        </template>
        <span>Добавить бренд к этому клиенту</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!client.deleted_at"
            class="mr-1"
            @click.stop="$emit('clientDialog')"
            v-bind="attrs"
            v-on="on"
            fab
            icon
          >
            <v-icon dark>
              mdi-lead-pencil
            </v-icon></v-btn
          >
        </template>
        <span>Редактировать клиента</span>
      </v-tooltip>
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!client.deleted_at"
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            @click.stop="removeClient(client)"
            fab
            
            icon
          >
            <v-icon>{{
              client.deleted_at ? "mdi-delete-restore" : "mdi-delete"
            }}</v-icon></v-btn
          >
        </template>
        <span>{{
          client.deleted_at ? "Восстановить клиента" : "Удалить клиента"
        }}</span>
      </v-tooltip> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { DELETE_CLIENT } from "@/store/const/clients";
import requestControl from "@/mixins/requestControl";
import { GET_CLIENTS_BY_AGENCY } from "../../../../store/const/clients";
export default {
  props: {
    client: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    }
  },
  components: {},
  mixins: [requestControl],

  methods: {
    ...mapActions("Clients", {
      deleteClient: DELETE_CLIENT,
      getClientsByAgency: GET_CLIENTS_BY_AGENCY
    }),
    async getClientByAgencyId(id) {
      await this.getClientsByAgency(id);
    },
    async removeClient(client) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.deleteClient(client);
        if (this.checkRequestOnError(DELETE_CLIENT)) {
          this.$notify({
            type: "succ",
            text: "Клиент успешно удален"
          });
          this.getClientByAgencyId(this.parent.id);
        }
      }
    }
  }
};
</script>

<style></style>
