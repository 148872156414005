<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-text-field
          color="secondary"
          outlined
          :dense="dense"
          style="font-size: 12px"
          :value="formatedRange"
          hide-details="auto"
          :label="label"
          prepend-inner-icon="mdi-calendar"
          readonly
          placeholder="Выберите даты"
          :disabled="disabled"
        ></v-text-field>
      </div>
    </template>
    <v-card class="px-2" outlined>
      <v-row class="justify-center">
        <v-col cols="12" sm="6">
          <v-date-picker
            color="secondary"
            :max="to"
            :disabled="disabled"
            :first-day-of-week="1"
            :value="from"
            @change="$emit('update:from', $event)"
          ></v-date-picker>
        </v-col>
        <v-col cols="12" sm="6">
          <v-date-picker
            color="secondary"
            :min="from"
            :disabled="disabled"
            :first-day-of-week="1"
            :value="to"
            @input="$emit('update:to', $event)"
          ></v-date-picker>
        </v-col>
      </v-row>
      <v-row class="mx-2 mb-2">
        <v-spacer> </v-spacer>
        <v-btn rounded class="dark--text mr-2" @click="cancel" text
          >Отмена</v-btn
        >
        <v-btn
          rounded
          color="secondary"
          class="dark--text"
          @click="
            $emit('confirm');
            menu = false;
          "
          >Применить</v-btn
        >
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    from: {
      type: [String, Date, undefined],
      required: true
    },
    to: {
      type: [String, Date, undefined],
      required: true
    },
    range: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: "Дата"
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: [String, undefined],
      required: false,
      default: null
    },
    max: {
      type: [String, undefined],
      required: false,
      default: null
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    formatedRange() {
      let from =
        new Date(this.from) == "Invalid Date"
          ? ""
          : new Date(this.from).toLocaleDateString();
      let to =
        new Date(this.to) == "Invalid Date"
          ? ""
          : new Date(this.to).toLocaleDateString();
      if (from != "" || to != "") {
        return from + " - " + to;
      } else {
        return "";
      }
    }
  },
  methods: {
    cancel() {
      this.menu = false;
      this.from = "";
      this.to = "";
    },
    closeWithoutSave() {
      this.$emit("update:from", "");
      this.$emit("update:to", "");
      this.menu = false;
    }
  }
};
</script>

<style></style>
