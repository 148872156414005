<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="702"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <v-btn rounded outlined v-bind="attrs" v-on="on">
        {{ buttonText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span>{{ isNew ? "Добавление бренда" : "Редактирование бренда" }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="isNew ? createNewBrand() : updateCurrentBrand()"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="form.name"
                :rules="rule.regularField"
                label="Название *"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="currentUserRole === 'root'" cols="12">
              <v-text-field
                type="number"
                v-model.number="form.charge"
                label="Charge *"
                :rules="[...rule.required, ...rule.minZero]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <small>* Помечены обязательные поля</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded text @click="closeDialog()">
          Отмена
        </v-btn>
        <v-btn
          rounded
          :loading="isLoading"
          v-if="isNew"
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="createNewBrand()"
        >
          Создать
        </v-btn>
        <v-btn
          :loading="isLoading"
          v-else
          rounded
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="updateCurrentBrand()"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { CREATE_BRAND, UPDATE_BRAND } from "@/store/const/brands";
import { GET_AGENCIES } from "@/store/const/agencies";

import { mapActions, mapGetters } from "vuex";
import { GET_BRANDS_BY_CLIENT } from "../../../store/const/brands";

/**
 * replaceWords
 * @param BRAND
 * @param Brand
 * @param BRANDS
 * @param brands
 * @param Brands
 * @param Бренды
 * @param Бренд
 * @param Brands

 */

let defaultForm = {
  name: "",
  charge: 0
};

export default {
  mixins: [validationRules, requestControl],
  props: {
    parentId: {
      type: Number,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    },
    entity: {
      type: Object,
      required: false,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null
    };
  },
  computed: {
    ...mapGetters("Auth", ["getUser"]),
    isLoading() {
      return (
        this.loading(CREATE_BRAND) === "loading" ||
        this.loading(UPDATE_BRAND) === "loading"
      );
    },
    currentUserRole() {
      const {
        type: { id }
      } = this.getUser;
      return id.toLowerCase();
    }
  },
  watch: {
    entity(newValue) {
      // выбирает только те элементы обьекта которые в последствии будут использоваться
      if (newValue) {
        let form = {};
        for (const key in defaultForm) {
          if (Object.hasOwnProperty.call(defaultForm, key)) {
            form[key] = newValue[key];
          }
        }

        this.form = Object.assign({}, form);
      } else {
        this.form = Object.assign({}, defaultForm);
      }
    }
  },
  methods: {
    ...mapActions("Brands", {
      createBrand: CREATE_BRAND,
      updateBrand: UPDATE_BRAND,
      getBrandsByClient: GET_BRANDS_BY_CLIENT
    }),
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),
    async createNewBrand() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        await this.createBrand({ ...this.form, client: this.parentId });
        if (this.checkRequestOnError(CREATE_BRAND)) {
          this.$notify({
            type: "succ",
            text: "Бренд успешно создан"
          });
          this.getBrandsByClient(this.parentId);
          this.closeDialog();
        }
      }
    },
    async updateCurrentBrand() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        await this.updateBrand({
          id: this.entity.id,
          data: { ...this.form, client: this.parentId }
        });
        if (this.checkRequestOnError(UPDATE_BRAND)) {
          this.$notify({
            type: "succ",
            text: "Бренд успешно обновлен"
          });
          this.getBrandsByClient(this.parentId);
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      this.form = Object.assign({}, defaultForm);
      this.$refs.form.resetValidation();
      // this.$refs.form.resetVal
    }
  }
};
</script>
