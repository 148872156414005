<template>
  <v-tooltip left top>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex" v-bind="attrs" v-on="on">
        <v-btn fab small class=" mr-2" depressed dark :color="type"
          ><h1>{{ badgeLetter }}</h1></v-btn
        >
        <div class="d-flex flex-column">
          <strong>{{ value.name }}</strong>
          <span class="text--disabled"></span>
        </div>
      </div>
    </template>
    <span>{{ type }} | id: {{ value.id }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    }
  },
  computed: {
    badgeLetter() {
      let letter = "";
      switch (this.type) {
        case "agency":
          letter = "A";
          break;
        case "client":
          letter = "C";
          break;
        case "brand":
          letter = "B";
          break;

        default:
          letter = "error";
          break;
      }
      return letter;
    }
  }
};
</script>

<style></style>
