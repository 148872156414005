<template>
  <v-fade-transition>
    <span id="agencies">
      <v-data-table
        :headers="defaultHeaders"
        :items="expandedAgencies.length === 1 ? expandedAgencies : agencies"
        :single-expand="true"
        :hide-default-footer="expandedAgencies.length === 1"
        :expanded.sync="expandedAgencies"
        item-key="name"
        show-expand
        class="elevation-0 border-top-0"
        :class="{ 'minified-header__active': expandedAgencies.length === 1 }"
        @item-expanded="getClientByAgencyId"
      >
        <template v-slot:[`header.option`]="{}">
          <div
            v-if="myRole === 'root'"
            @click="openAgencyCreateDialog"
            class="d-flex align-center justify-end"
          >
            <v-icon>mdi-plus</v-icon>
            <span>Добавить агентство</span>
          </div>
        </template>

        <template v-slot:item="{ item: agency, expand, isExpanded }">
          <tr @click="expand(!isExpanded)" class="expanded-row">
            <td>
              <entity-table-info
                :value="agency"
                type="agency"
              ></entity-table-info>
            </td>
            <td v-if="agency.charge || agency.charge === 0">
              <span>{{ `${agency.charge}%` }}</span>
            </td>
            <td>
              <agency-actions
                @agencyDialog="openEditAgencyDialog(agency)"
                @clientDialog="openClientCreateDialog(agency)"
                :agency="agency"
              ></agency-actions>
            </td>
            <td>
              <v-icon class="expanded" :class="{ rotate: isExpanded }"
                >mdi-chevron-down</v-icon
              >
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" class="pa-0 bg-gray">
            <v-data-table
              :class="{
                'current-entity__active': viewedEntity === 'client',
                'minified-header__active': expandedClients.length === 1
              }"
              :headers="defaultHeaders"
              :loading="isClientLoading"
              :items="expandedClients.length === 1 ? expandedClients : clients"
              :hide-default-footer="expandedClients.length === 1"
              :single-expand="true"
              item-key="name"
              show-expand
              :expanded.sync="expandedClients"
              class="elevation-0 border-top-0"
              @item-expanded="getBrandByClientId"
            >
              <template v-slot:item="{ item: client, expand, isExpanded }">
                <tr @click="expand(!isExpanded)" class="expanded-row">
                  <td>
                    <entity-table-info
                      :value="client"
                      type="client"
                    ></entity-table-info>
                  </td>
                  <td v-if="client.charge || client.charge === 0">
                    {{ `${client.charge}%` }}
                  </td>
                  <td>
                    <client-actions
                      @clientDialog="
                        openClientEditDialog(client, expandedAgencies)
                      "
                      @brandDialog="openBrandCreateDialog(client)"
                      :client="client"
                      :parent="expandedAgencies[0]"
                    ></client-actions>
                  </td>
                  <td>
                    <v-icon class="expanded" :class="{ rotate: isExpanded }"
                      >mdi-chevron-down</v-icon
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length" class="pa-0 bg-gray">
                  <v-data-table
                    :class="{
                      'current-entity__active': viewedEntity === 'brand',
                      'minified-header__active': expandedBrands.length === 1
                    }"
                    :headers="defaultHeaders"
                    :items="
                      expandedBrands.length === 1 ? expandedBrands : brands
                    "
                    :loading="isBrandLoading"
                    :hide-default-footer="expandedBrands.length === 1"
                    :single-expand="true"
                    item-key="name"
                    show-expand
                    :expanded.sync="expandedBrands"
                    class="elevation-0 border-top-0"
                    @item-expanded="getOrderByBrandId"
                  >
                    <template v-slot:item="{ item: brand, expand, isExpanded }">
                      <tr @click="expand(!isExpanded)" class="expanded-row">
                        <td>
                          <entity-table-info
                            :value="brand"
                            type="brand"
                          ></entity-table-info>
                        </td>
                        <td v-if="brand.charge || brand.charge === 0">
                          {{ `${brand.charge}%` }}
                        </td>
                        <td>
                          <brand-actions
                            @reservationDialog="openBrandEditDialog(brand)"
                            @brandDialog="
                              openBrandEditDialog(brand, expandedClients[0])
                            "
                            :brand="brand"
                            :parent="expandedClients[0]"
                          ></brand-actions>
                        </td>
                        <td>
                          <v-icon
                            class="expanded"
                            :class="{ rotate: isExpanded }"
                            >mdi-chevron-down</v-icon
                          >
                        </td>
                      </tr>
                    </template>

                    <template v-slot:[`header.option`]="{}"></template>

                    <template v-slot:expanded-item="{ headers, item: brand }">
                      <td :colspan="headers.length" class="pa-0 bg-gray">
                        <div>
                          <orders-table
                            ref="orderTable"
                            :class="{
                              'current-entity__active': viewedEntity === 'order'
                            }"
                            @updateAction="getOrderByBrandId({ item: brand })"
                            @onCurrentBrandPopupOpen="
                              currentBrandPopupOpenHandler
                            "
                            is-nested
                          ></orders-table>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>

      <agency-popup
        :is-new="isNewEntity"
        :entity="currentEntity"
        v-model="agencyDialogState"
      ></agency-popup>
      <client-popup
        :is-new="isNewEntity"
        v-model="clientDialogState"
        :entity="currentEntity"
        :parent-id="parentId"
      ></client-popup>
      <brand-popup
        :is-new="isNewEntity"
        v-model="brandDialogState"
        :entity="currentEntity"
        :parent-id="parentId"
      ></brand-popup>
    </span>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { GET_AGENCIES, DELETE_AGENCY } from "@/store/const/agencies";
import { GET_CLIENTS_BY_AGENCY } from "../../../../store/const/clients";
import { GET_BRANDS_BY_CLIENT } from "../../../../store/const/brands";
import { GET_ORDERS_BY_BRAND } from "../../../../store/const/orders";
import requestControl from "@/mixins/requestControl";
import agencyActions from "./agencyActions";
import clientActions from "./clientActions";
import brandActions from "./brandActions";
import ordersTable from "./OrderTableView";
import agencyPopup from "@/components/management/agencies/Popup.vue";
import clientPopup from "@/components/management/clients/Popup.vue";
import brandPopup from "@/components/management/brands/Popup.vue";
import entityTableInfo from "./EntityTableInfo";
export default {
  components: {
    agencyPopup,
    clientPopup,
    brandPopup,
    agencyActions,
    clientActions,
    brandActions,
    ordersTable,
    entityTableInfo
  },
  mixins: [requestControl],
  props: {
    reservation: {
      type: Object
    }
  },
  data() {
    return {
      agencyPanel: "",
      clientPanel: "",
      brandPanel: "",
      expandedAgencies: [],
      expandedClients: [],
      expandedBrands: [],
      currentEntity: {
        id: 100000
      },
      isNewEntity: false,
      parentId: 100000,
      clientDialogState: false,
      agencyDialogState: false,
      brandDialogState: false,
      currentAgencyId: "",
      currentClientId: "",
      currentBrandId: ""
    };
  },
  computed: {
    ...mapState("Helpers", ["showTrashedEntities"]),
    ...mapState("Agencies", ["agencies"]),
    ...mapState("Clients", ["clients"]),
    ...mapState("Brands", ["brands"]),
    ...mapState("Orders", ["orders"]),
    ...mapGetters("Auth", ["myRole"]),
    ...mapGetters("Reservation", {
      navigationTree: "getNavigationTree"
    }),
    isLoading() {
      return this.loading(GET_AGENCIES) === "loading";
    },
    isClientLoading() {
      return this.loading(GET_CLIENTS_BY_AGENCY) === "loading";
    },
    isBrandLoading() {
      return this.loading(GET_BRANDS_BY_CLIENT) === "loading";
    },
    isOrderLoading() {
      return this.loading(GET_ORDERS_BY_BRAND) === "loading";
    },
    defaultHeaders() {
      let headers = [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name",
          width: "40%"
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "option"
        },
        {
          text: "",
          width: "30px",
          value: "data-table-expand",
          align: "right"
        }
      ];
      if (this.agencies[0] && this.agencies[0].charge) {
        headers.splice(1, 0, {
          text: "Charge",
          align: "start",
          sortable: false,
          value: "charge"
        });
      }
      return headers;
    },
    viewedEntity() {
      if (this.expandedAgencies.length === 1) {
        if (this.expandedClients.length === 1) {
          if (this.expandedBrands.length === 1) {
            return "order";
          }
          return "brand";
        }
        return "client";
      }
      return "agency";
    }
    // agencyFilter(items, search, filter){
    //     if(this.agencyFilterId){
    //             return items.filter(row => row.id === this.agencyFilterId)
    //     }
    //     return items
    // }
  },
  watch: {
    expandedAgencies({ length }) {
      if (length === 0) {
        this.expandedClients = [];
        this.expandedBrands = [];
      }
    },
    expandedClients({ length }) {
      if (length === 0) {
        this.expandedBrands = [];
      }
    },
    agencyPanel() {
      this.clientPanel = "";
      this.brandPanel = "";
    },
    async showTrashedEntities() {
      if (this.currentAgencyId != "") {
        await this.getClientByAgencyId(this.currentAgencyId);
        if (
          this.currentClientId != "" &&
          this.clients.some(client => client.id == this.currentClientId)
        ) {
          await this.getBrandsByClient(this.currentClientId);
        }
        if (
          this.currentBrandId != "" &&
          this.brands.some(brand => brand.id == this.currentBrandId)
        ) {
          this.getOrderByBrandId({ item: { id: this.currentBrandId } });
        }
      }
    }
  },
  mounted() {
    if (this.navigationTree) this.reopenBooking();
  },
  methods: {
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES,
      deleteAgency: DELETE_AGENCY
    }),
    ...mapActions("Clients", {
      getClientsByAgency: GET_CLIENTS_BY_AGENCY
    }),
    ...mapActions("Brands", {
      getBrandsByClient: GET_BRANDS_BY_CLIENT
    }),
    ...mapActions("Orders", {
      getOrdersByBrand: GET_ORDERS_BY_BRAND
    }),
    ...mapMutations("Clients", { setClientsByAgency: GET_CLIENTS_BY_AGENCY }),
    ...mapMutations("Brands", { setBrandsByClient: GET_BRANDS_BY_CLIENT }),
    ...mapMutations("Orders", { setOrdersByBrand: GET_ORDERS_BY_BRAND }),
    async reopenBooking() {
      try {
        console.log("this.navigationTree", this.navigationTree);
        this.expandedAgencies = [
          this.agencies.find(a => a.id === this.navigationTree.agency)
        ];
        await this.getClientByAgencyId({
          item: this.expandedAgencies[0],
          value: true
        });

        this.expandedClients = [
          this.clients.find(a => a.id === this.navigationTree.client)
        ];
        await this.getBrandByClientId({
          item: this.expandedClients[0],
          value: true
        });

        this.expandedBrands = [
          this.brands.find(a => a.id === this.navigationTree.brand)
        ];
        await this.getOrderByBrandId({
          item: this.expandedBrands[0],
          value: true
        });
        this.$refs.orderTable.$refs[
          "idReservation" + this.navigationTree.reservation
        ].$el.scrollIntoView({ block: "start", scrollBehavior: "smooth" });
        //console.log("top", top);
        //setTimeout({})
        //window.scrollTo(0, top);
      } catch (e) {
        console.log("error", e);
      }
    },
    async removeAgency(agency) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.deleteAgency(agency);
        if (this.checkRequestOnError(DELETE_AGENCY)) {
          this.$notify({
            type: "succ",
            text: "Агентство успешно удалено"
          });
          this.fetchAgencies();
        }
      }
    },
    async getClientByAgencyId({ item: agency, value: isOpen }) {
      console.log("agency", agency);
      console.log("isOpen", isOpen);
      const id = agency.id;
      this.$store.commit("Reservation/setNavigationTree", {
        ...this.navigationTree,
        agency: id
      });
      this.currentAgencyId = id;
      this.brandPanel = "";
      if (isOpen) {
        await this.getClientsByAgency(id);
        if (!this.checkRequestOnError(GET_CLIENTS_BY_AGENCY)) {
          this.setClientsByAgency({ data: [] });
        }
      } else {
        this.setClientsByAgency({ data: [] });
      }
    },
    async getBrandByClientId({ item: client, value: isOpen }) {
      const id = client.id;
      this.currentClientId = id;
      this.$store.commit("Reservation/setNavigationTree", {
        ...this.navigationTree,
        client: id
      });
      // this.$store.commit(`Brands/${GET_BRANDS_BY_CLIENT}`, { data: [] });
      this.brandPanel = "";
      if (isOpen) {
        await this.getBrandsByClient(id);
        if (!this.checkRequestOnError(GET_BRANDS_BY_CLIENT)) {
          this.setBrandsByClient({ data: [] });
        }
      }
    },
    async getOrderByBrandId({ item: brand, value: isOpen = true }) {
      const id = brand.id;
      this.currentBrandId = id;

      this.$store.commit("Reservation/setNavigationTree", {
        ...this.navigationTree,
        brand: id
      });
      if (isOpen) {
        await this.getOrdersByBrand(id);
        if (!this.checkRequestOnError(GET_ORDERS_BY_BRAND)) {
          this.getOrdersByBrand({ data: [] });
        }
      }
    },
    //// agency
    openEditAgencyDialog(agency) {
      this.currentEntity = agency;
      this.isNewEntity = false;
      this.agencyDialogState = true;
    },
    openAgencyCreateDialog() {
      this.currentEntity = void 0;
      this.parentId = void 0;
      this.isNewEntity = true;
      this.agencyDialogState = true;
    },
    /// client
    openClientCreateDialog(agency) {
      this.currentEntity = void 0;
      this.parentId = agency.id;
      this.isNewEntity = true;
      this.clientDialogState = true;
    },
    openClientEditDialog(client, parent) {
      this.currentEntity = client;
      this.parentId = parent.id;
      this.isNewEntity = false;
      this.clientDialogState = true;
    },
    openBrandEditDialog(brand, parent) {
      this.currentEntity = brand;
      this.parentId = parent.id;
      this.isNewEntity = false;
      this.brandDialogState = true;
    },
    openBrandCreateDialog(client) {
      this.currentEntity = void 0;
      this.parentId = client.id;
      this.isNewEntity = true;
      this.brandDialogState = true;
    },
    currentBrandPopupOpenHandler(brand) {
      this.$emit("onCurrentBrandPopupOpen", brand);
    }
  }
};
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}

#agencies .v-expansion-panel-content__wrap {
  padding: 5px;
}

.expanded {
  transition: 0.3s linear;

  &.rotate {
    transform: rotate(-180deg);
  }
}

.expanded-row {
  &:hover {
    cursor: pointer;
  }
}
</style>
