<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col class="d-flex pa-1 justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!brand.deleted_at"
            class="mr-1"
            :to="{
              name: 'newReservation',
              params: { id: brand.id },
              query: {
                brand: brand.name
              }
            }"
            @click.stop
            v-bind="attrs"
            v-on="on"
            fab
            icon
          >
            <v-icon dark>
              mdi-shield-plus
            </v-icon></v-btn
          >
        </template>
        <span>Создать бронь этого бренда</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!brand.deleted_at"
            class="mr-1"
            @click.stop="$emit('brandDialog')"
            v-bind="attrs"
            v-on="on"
            fab
            icon
          >
            <v-icon dark>
              mdi-lead-pencil
            </v-icon></v-btn
          >
        </template>
        <span>Редактировать бренд</span>
      </v-tooltip>
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!brand.deleted_at"
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            @click.stop="removeBrand(brand)"
            fab
            
            icon
          >
            <v-icon>{{
              brand.deleted_at ? "mdi-delete-restore" : "mdi-delete"
            }}</v-icon></v-btn
          >
        </template>
        <span>{{
          brand.deleted_at ? "Восстановить бренд" : "Удалить бренд"
        }}</span>
      </v-tooltip> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { GET_AGENCIES } from "@/store/const/agencies";
import { DELETE_BRAND } from "@/store/const/brands";
import requestControl from "@/mixins/requestControl";
import { GET_BRANDS_BY_CLIENT } from "../../../../store/const/brands";
export default {
  props: {
    brand: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    }
  },
  components: {},
  mixins: [requestControl],
  methods: {
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),
    ...mapActions("Brands", {
      deleteBrand: DELETE_BRAND,
      getBrandsByClientId: GET_BRANDS_BY_CLIENT
    }),

    async removeBrand(brand) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.deleteBrand(brand);
        if (this.checkRequestOnError(DELETE_BRAND)) {
          this.$notify({
            type: "succ",
            text: "Бренд успешно удален"
          });
          this.getBrandsByClientId(this.parent.id);
        }
      }
    }
  }
};
</script>

<style></style>
