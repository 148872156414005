<template>
  <v-card style="height: 100%">
    <v-card-title class="fz-14 mb-0 py-0">
      <v-row>
        <v-col cols="12">
          {{ order.name }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <div class="subtitle-text" v-if="order.agency">
        Агентство: {{ order.brand.client.agency.name }}
      </div>
      <h6 class="text--primary subtitle-text">
        {{ order.brand.name }} / {{ order.brand.client.name }}
      </h6>
    </v-card-subtitle>
    <v-divider></v-divider>
    <div>
      <v-list>
        <v-list-item>
          <v-list-item-content>Статус:</v-list-item-content>
          <status-chip
            :status="order.status.id"
            :description="order.status.description"
          ></status-chip>
        </v-list-item>
      </v-list>

      <v-list dense style="align-self: flex-end">
        <v-list-item>
          <v-list-item-content>Перейти:</v-list-item-content>
          <v-list-item-content class="align-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  target="_blank"
                  color="primary"
                  class="black--text custom-width-button"
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  :to="{
                    name: 'updateReservation',
                    params: {
                      id: order.id
                    }
                  }"
                >
                  <span
                    class="text-truncate"
                    style="min-width: 30px;max-width: 100%"
                    >{{ order.name }}</span
                  >
                  <v-icon small right>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Открыть "{{ order.name }}" в новой вкладке</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item>
          <v-list-item-content>Опции:</v-list-item-content>
          <order-actions
            @openPopup="$emit('openPopup')"
            :order="order"
          ></order-actions>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import statusChip from "@/components/common/statusChip";
import orderActions from "./Actions";

export default {
  components: {
    statusChip,
    orderActions
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
