var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":10,"fixed-header":"","loading":_vm.isLoading,"disabled":_vm.isLoading,"loader-height":"6","loading-text":"Загрузка списка... Пожалуйста подождите ","item-class":_vm.isDeleted,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"header.options",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(" "+_vm._s(header.text)+" ")]),_c('v-menu',{staticStyle:{"z-index":"200"},attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 dark--text",attrs:{"fab":"","icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, menu, tooltip)),[_c('h2',[_vm._v("CC")])])]}}],null,true)},[_c('span',[_vm._v("Пользовательские поля")])])]}}],null,true)},[_c('v-list',{staticClass:"mb-2"},_vm._l((_vm.allHeaders),function(header){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!['Агентство', 'Клиент', 'Бренд'].includes(header.text)),expression:"!['Агентство', 'Клиент', 'Бренд'].includes(header.text)"}],key:header.text},[_c('v-checkbox',{attrs:{"disabled":['Название', 'Опции'].includes(header.text),"label":header.text,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.$store.commit('Orders/updateOrderHeaders', _vm.allHeaders)}},model:{value:(header.visible),callback:function ($$v) {_vm.$set(header, "visible", $$v)},expression:"header.visible"}})],1)}),1)],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',_vm._g({attrs:{"color":"accent","content":"Нестандарт","overlap":"","value":item.exclusive}},on),[_c('v-btn',_vm._g(_vm._b({ref:'idReservation' + item.id,staticClass:"black--text",attrs:{"color":"black","rounded":"","outlined":"","text":"","ripple":false,"to":{
                name: 'updateReservation',
                params: { id: item.id }
              }},on:{"click":function($event){return _vm.$store.commit('Reservation/setNavigationTree', Object.assign({}, _vm.$store.state.Reservation.navigationTree,
                  {reservation: item.id}))}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text-truncate",staticStyle:{"min-width":"100px","max-width":"262px"}},[_vm._v(_vm._s(item.name))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-open-in-new")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Открыть "+_vm._s(item.exclusive ? "нестандартную" : "")+" бронь \""+_vm._s(item.name)+"\" в новой вкладке")])])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('status-chip',{attrs:{"status":item.status.id,"description":item.status.description}})]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [(item.created_at)?_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))]):_vm._e()]}},{key:"item.deleted_at",fn:function(ref){
                var item = ref.item;
return [(item.deleted_at)?_c('span',[_vm._v(_vm._s(new Date(item.deleted_at).toLocaleString()))]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
                var item = ref.item;
return [(item.updated_at)?_c('span',[_vm._v(_vm._s(new Date(item.updated_at).toLocaleString()))]):_vm._e()]}},{key:"item.done_at",fn:function(ref){
                var item = ref.item;
return [(item.done_at)?_c('span',[_vm._v(_vm._s(new Date(item.done_at).toLocaleString()))]):_vm._e()]}},{key:"item.calc_deadline",fn:function(ref){
                var item = ref.item;
return [(item.calc_deadline)?_c('span',[_vm._v(_vm._s(item.calc_deadline))]):_c('span',[_vm._v("Не задано")])]}},{key:"item.keepers",fn:function(ref){
                var item = ref.item;
return [(item.keepers.length === 0)?_c('span',[_vm._v(" -")]):_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.keepers[0].name))]),(item.keepers.length > 1)?_c('popover',{attrs:{"title":"Ответственные","data":item,"main-prop":"keepers"}}):_vm._e()],1)]}},{key:"item.options",fn:function(ref){
                var item = ref.item;
return [_c('order-actions',{attrs:{"order":item},on:{"openPopup":function($event){return _vm.openDialog(item)},"updateAction":function($event){return _vm.$emit('updateAction', 'search')}}})]}}],null,true)}),_c('order-popup',{attrs:{"is-new":false,"entity":_vm.currentEntity},on:{"updateAction":function($event){return _vm.$emit('updateAction', 'search')}},model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }