<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="702"
    style="min-height: 305px"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        data-cy="agency-popup-handler"
        :block="$vuetify.breakpoint.smAndDown"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span>{{
          isNew ? "Добавление Агентства" : "Редактирование Агентства"
        }}</span>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          :loading="isLoading"
          v-if="isNew"
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="createNewAgency()"
        >
          Создать
        </v-btn>

        <v-btn
          rounded
          data-cy="agency-create"
          :loading="isLoading"
          v-else
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="updateCurrentAgency()"
        >
          Сохранить
        </v-btn>
        <v-btn roundedcolor="secondary" class="ml-2" @click="closeDialog()">
          Отмена
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          class="my-5"
          ref="form"
          @submit.prevent="isNew ? createNewAgency() : updateCurrentAgency()"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="8">
              <v-text-field
                color="secondary"
                outlined
                dense
                data-cy="agency-name"
                v-model.trim="form.name"
                :rules="rule.regularField"
                label="Название агентства"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="currentUserRole === 'root'" cols="4">
              <v-text-field
                color="secondary"
                outlined
                dense
                data-cy="agency-charge"
                type="number"
                v-model.number="form.charge"
                label="Charge"
                :rules="[...rule.required, ...rule.minZero]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <small class="d-flex align-center"
          ><v-icon>mdi-alarm-light</v-icon
          ><span class="ml-2">Все поля обязательны для заполнения</span>
        </small>
      </v-card-text>

      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { mapGetters } from "vuex";

import {
  CREATE_AGENCY,
  UPDATE_AGENCY,
  GET_AGENCIES
} from "@/store/const/agencies";
import { mapActions } from "vuex";

let defaultForm = {
  name: "",
  charge: 0
};

export default {
  mixins: [validationRules, requestControl],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    },
    entity: {
      type: Object,
      required: false,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null
    };
  },
  computed: {
    ...mapGetters("Auth", ["getUser"]),
    isLoading() {
      return (
        this.loading(CREATE_AGENCY) === "loading" ||
        this.loading(UPDATE_AGENCY) === "loading"
      );
    },
    currentUserRole() {
      const {
        type: { id }
      } = this.getUser;
      return id.toLowerCase();
    }
  },
  watch: {
    entity(newValue) {
      // выбирает только те элементы обьекта которые в последствии будут использоваться
      if (newValue) {
        let form = {};
        for (const key in defaultForm) {
          if (Object.hasOwnProperty.call(defaultForm, key)) {
            form[key] = newValue[key];
          }
        }
        this.form = Object.assign({}, form);
      } else {
        this.form = Object.assign({}, defaultForm);
      }
    }
  },
  methods: {
    ...mapActions("Agencies", {
      createAgency: CREATE_AGENCY,
      fetchAgencies: GET_AGENCIES,
      updateAgency: UPDATE_AGENCY
    }),
    async createNewAgency() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          await this.createAgency(this.form);
        } catch (_) {
          _;
        }
        if (this.checkRequestOnError(CREATE_AGENCY)) {
          this.$notify({
            type: "succ",
            text: "Агентство успешно создано"
          });
          this.fetchAgencies();
          this.closeDialog();
        }
      }
    },
    async updateCurrentAgency() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          await this.updateAgency({ id: this.entity.id, data: this.form });
        } catch (_) {
          _;
        }
        if (this.checkRequestOnError(UPDATE_AGENCY)) {
          this.$notify({
            type: "succ",
            text: "Агентство успешно обновлено"
          });
          this.fetchAgencies();
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      this.form = Object.assign({}, defaultForm);
      this.$refs.form.resetValidation();
      // this.$refs.form.resetVal
    }
  }
};
</script>
