<template>
  <v-skeleton-loader max-width="200" type="table-cell" :loading="loading">
    <v-chip
      v-show="formatStatus.name != ''"
      :dark="formatStatus.dark"
      :small="small"
      :color="formatStatus.color"
      class="v-chip--multiline"
      >{{ formatStatus.name }}</v-chip
    >
  </v-skeleton-loader>
</template>

<script>
export default {
  name: "statusChip",
  props: {
    status: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    formatStatus() {
      switch (this.status) {
        case "New":
          return {
            name: this.description || "Новая",
            color: "#B2D732",
            dark: true
          };
        case "ReviewingByTrader":
          return {
            name: this.description || "На рассмотрении у трейдера",
            color: "#26c292",
            dark: true
          };
        case "ApproveByTrader":
          return {
            name: this.description || "Согласовано трейдером",
            color: "#FCCC1A",
            dark: true
          };
        case "ReviewingByClient":
          return {
            name: this.description || "На рассмотрении у клиента",
            color: "#FB9902",
            dark: true
          };
        case "ApproveByClient":
          return {
            name: this.description || "Согласовано клиентом",
            color: "#FC600A",
            dark: true
          };
        case "ProcessingByTrader":
          return {
            name: this.description || "Принято в работу трейдером",
            color: "#C21460",
            dark: true
          };
        case "WaitingOfCreatives":
          return {
            name: this.description || "Ожидаются материалы",
            color: "#8601AF",
            dark: true
          };
        case "Moderation":
          return {
            name: this.description || "На модерации",
            color: "#4424D6",
            dark: true
          };
        case "InProgress":
          return {
            name: this.description || "В размещении",
            color: "#66B032",
            dark: true
          };
        case "Stopped":
          return {
            name: this.description || "Остановлена",
            color: "#FE2712",
            dark: true
          };
        case "Done":
          return {
            name: this.description || "Завершена",
            color: "#347C98",
            dark: true
          };
        case "Prolongation":
          return {
            name: this.description || "Пролонгация",
            color: "#0247FE",
            dark: true
          };
        default:
          return {
            name: this.description || this.status,
            color: "warning",
            dark: true
          };
      }
    }
  }
};
</script>

<style>
.v-chip--multiline {
  white-space: break-spaces;
  height: auto !important;
}
</style>
