<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="702"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <v-btn outlined v-bind="attrs" v-on="on">
        {{ buttonText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span>Назначить ответственного </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="updateCurrentOrder()"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-select
                label="Аккаунт"
                multiple
                :items="accounts"
                item-text="name"
                item-value="id"
                required
                v-model="form.accounts"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Трейдер "
                multiple
                :items="traders"
                item-text="name"
                item-value="id"
                required
                v-model="form.traders"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Менеджер"
                multiple
                :items="allClients"
                item-text="name"
                item-value="id"
                required
                v-model="form.managers"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <!-- <small>* Помечены обязательные поля</small> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog()">
          Отмена
        </v-btn>

        <v-btn
          :loading="isLoading"
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="updateCurrentOrder()"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { UPDATE_ORDER_META } from "@/store/const/orders";
import { GET_AGENCIES } from "@/store/const/agencies";
import { mapActions, mapGetters } from "vuex";
import { EDIT_KEEPER_STATUS_FOR_ORDER } from "../../../store/const/orders";
import dbg from "@/plugins/dbg";

let defaultForm = {
  traders: [],
  managers: [],
  accounts: []
};
export default {
  mixins: [validationRules, requestControl],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    },
    entity: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null,
      oldValues: []
    };
  },
  computed: {
    ...mapGetters("Users", {
      accounts: "getAccountUsers",
      traders: "getTraderUsers",
      allClients: "getAllClientUsers"
    }),
    isLoading() {
      return this.loading(UPDATE_ORDER_META) === "loading";
    }
  },

  watch: {
    entity(newValue) {
      // выбирает только те элементы обьекта которые в последствии будут использоваться
      if (newValue) {
        this.form = Object.assign({}, this.parseFromKeepers(newValue.keepers));
        this.oldValues = JSON.parse(JSON.stringify(this.form));
      } else {
        this.form = Object.assign({}, defaultForm);
        this.oldValues = JSON.parse(JSON.stringify(this.form));
      }
    }
  },

  methods: {
    ...mapActions("Orders", {
      updateOrderMeta: UPDATE_ORDER_META
    }),
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),
    ...mapActions("Orders", {
      editKeeperStatus: EDIT_KEEPER_STATUS_FOR_ORDER
    }),
    async allSettledHandler(promisedArray) {
      let response = await Promise.allSettled(promisedArray);
      const rejected = response.filter(item => item.status !== "fulfilled");
      const confirmed = response.filter(item => item.status === "fulfilled");
      dbg(rejected,confirmed)
      if (rejected.length == 0) {
        this.$notify({
          type: "succ",
          title: "Успешно"
        });
      } else {
        this.$notify({
          type: "err",
          title: "Ошибка",
          text:
            rejected.length > 1
              ? "Несколько элементов не было изменено"
              : "1 Элемент не был изменён"
        });
        if (confirmed.length > 0) {
          this.$notify({
            type: "succ",
            title: "Частично успешно"
          });
        }
        this.$emit("updateAction");
      }
      // await this.fetchUsers();
      this.$emit("update:entityList", []);
      return response;
    },
    parseFromKeepers(keepers) {
      return {
        traders: keepers.filter(keeper => keeper.type?.id === "Trader"),
        managers: keepers.filter(keeper => keeper.type?.id === "Manager"),
        accounts: keepers.filter(keeper => keeper.type?.id === "Account")
      };
    },
    getArraysForDeletedAndAdded(array1, array2) {
      let filtered = array1.filter(value => array2.includes(value));
      return {
        firstArray: array1.filter(value => !filtered.includes(value)),
        secondArray: array2.filter(value => !filtered.includes(value))
      };
    },
    async massChangeStatus(changeStatusFunc, entityList) {
      let promisedArray = new Array();
      for (const item of entityList) {
        promisedArray.push(changeStatusFunc(item));
      }
      return await this.allSettledHandler(promisedArray);
    },
    editKeeperStatusByVerb(verb) {
      //carry helper edit status func for async fabric)
      return async user => {
        await this.editKeeperStatus({
          id: this.entity.id,
          verb,
          user: typeof user === "object" ? user.id : user
        });
      };
    },
    async updateCurrentOrder() {
      // const isValid = this.$refs.form.validate();
      for (const entity in this.form) {
        if (Object.hasOwnProperty.call(this.form, entity)) {
          const {
            firstArray: addingIds,
            secondArray: deletedIds
          } = this.getArraysForDeletedAndAdded(
            this.form[entity],
            this.oldValues[entity]
          );

          if (addingIds.length > 0) {
            await this.massChangeStatus(
              this.editKeeperStatusByVerb("change"),
              addingIds
            );
          }
          if (deletedIds.length > 0) {
            await this.massChangeStatus(
              this.editKeeperStatusByVerb("remove"),
              deletedIds
            );
          }
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      // this.form = Object.assign({}, defaultForm);
      this.$refs.form.resetValidation();
      // this.$refs.form.resetVal
    }
  }
};
</script>
