<template>
  <v-card
    class="elevation-0 position-relative v-card__outer-header"
    :color="disabled ? 'grey lighten-4' : null"
  >
    <v-row class="px-2 " style="flex-wrap: nowrap">
      <v-col cols="12" sm="6" md="auto" v-if="show.statuses">
        <v-combobox
          color="secondary"
          dense
          outlined
          item-text="description"
          item-value="key"
          label="Статус"
          hide-details
          multiple
          :disabled="disabled || isStatusesLoading"
          :items="statusArray"
          :value="statuses"
          @change="
            $emit('update:statuses', $event);
            $emit('callRequest');
          "
        >
          <template #selection="{item}">
            <!-- <div class=""> -->
            <status-chip
              class="ma-1"
              small
              style="margin: auto"
              :status="item.id"
              :description="item.description"
            ></status-chip>
            <!-- </div> -->
          </template>
          <template #item="{item}">
            <!-- <div class=""> -->
            <status-chip
              style="margin: 0 0 auto"
              :status="item.id"
              :description="item.description"
            ></status-chip>
            <!-- </div> -->
          </template>
        </v-combobox>
      </v-col>
      <!--      <v-col cols="12" sm="6" md="auto" v-if="show.account">-->
      <!--        <v-combobox-->
      <!--          color="secondary"-->
      <!--          dense-->
      <!--          outlined-->
      <!--          item-text="name"-->
      <!--          label="Аккаунт"-->
      <!--          hide-details-->
      <!--          multiple-->
      <!--          :disabled="disabled"-->
      <!--          :items="accounts"-->
      <!--          :value="a"-->
      <!--          @change="-->
      <!--            $emit('update:a', $event);-->
      <!--            $emit('callRequest');-->
      <!--          "-->
      <!--        ></v-combobox>-->
      <!--      </v-col>-->
      <v-col cols="12" sm="6" md="auto" v-if="show.prioritet">
        <v-select
          dense
          outlined
          item-text="description"
          label="Приоритет"
          hide-details
          multiple
          :disabled="disabled"
          :items="prioritiesArr"
          :value="priorities"
          @change="
            $emit('update:priorities', $event);
            $emit('callRequest');
          "
        ></v-select>
      </v-col>
      <!--      <v-col cols="12" sm="6" md="auto" v-if="show.manager">-->
      <!--        <v-combobox-->
      <!--          color="secondary"-->
      <!--          dense-->
      <!--          outlined-->
      <!--          item-text="name"-->
      <!--          label="Менеджер"-->
      <!--          hide-details-->
      <!--          multiple-->
      <!--          :disabled="disabled"-->
      <!--          :items="managers"-->
      <!--          :value="m"-->
      <!--          @change="-->
      <!--            $emit('update:m', $event);-->
      <!--            $emit('callRequest');-->
      <!--          "-->
      <!--        ></v-combobox>-->
      <!--      </v-col>-->
      <!--      <v-col cols="12" sm="6" md="auto" v-if="show.trader">-->
      <!--        <v-combobox-->
      <!--          color="secondary"-->
      <!--          dense-->
      <!--          outlined-->
      <!--          item-text="name"-->
      <!--          label="Трейдер"-->
      <!--          hide-details-->
      <!--          multiple-->
      <!--          :disabled="disabled"-->
      <!--          :items="traders"-->
      <!--          :value="t"-->
      <!--          @change="-->
      <!--            $emit('update:t', $event);-->
      <!--            $emit('callRequest');-->
      <!--          "-->
      <!--        ></v-combobox>-->
      <!--      </v-col>-->
      <v-col cols="12" sm="6" md="auto" v-if="show.created">
        <range-date-picker
          dense
          :disabled="disabled"
          range
          :from="created_from"
          @update:from="$emit('update:created_from', $event)"
          @update:to="$emit('update:created_to', $event)"
          :to="created_to"
          label="Создано"
          @confirm="$emit('callRequest')"
        ></range-date-picker>
      </v-col>
      <v-col cols="12" sm="6" v-if="show.updated" md="auto">
        <range-date-picker
          dense
          :disabled="disabled"
          range
          :from="updated_from"
          @update:from="$emit('update:updated_from', $event)"
          @update:to="$emit('update:updated_to', $event)"
          :to="updated_to"
          label="Обновлено"
          @confirm="$emit('callRequest')"
        ></range-date-picker>
      </v-col>
      <v-col cols="12" sm="6" v-if="show.deleted" md="auto">
        <range-date-picker
          dense
          :disabled="disabled"
          range
          :from="deleted_from"
          @update:from="$emit('update:deleted_from', $event)"
          @update:to="$emit('update:deleted_to', $event)"
          :to="deleted_to"
          label="Удалено"
          @confirm="$emit('callRequest')"
        ></range-date-picker>
      </v-col>
      <v-col cols="12" sm="6" md="auto" v-if="show.done">
        <range-date-picker
          dense
          :disabled="disabled"
          range
          :from="done_from"
          @update:from="$emit('update:done_from', $event)"
          @update:to="$emit('update:done_to', $event)"
          :to="done_to"
          label="Выполнено"
          @confirm="$emit('callRequest')"
        ></range-date-picker>
      </v-col>
      <v-col cols="12" md="auto">
        <div class="d-flex flex-end">
          <v-divider vertical></v-divider>
          <v-menu style="z-index:200" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...menu, ...tooltip }"
                    class="mx-1 dark--text"
                    fab
                    icon
                    small
                    ><h2>CC</h2></v-btn
                  >
                </template>
                <span>Пользовательские поля</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item>
                <v-checkbox
                  v-model="show.statuses"
                  label="Статус"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="show.created"
                  label="Созданные"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="show.exclusive"
                  label="Нестандартные"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="show.prioritet"
                  label="Приоритет"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="show.done"
                  label="Выполненые"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item v-if="currentUserRole === 'root'">
                <v-checkbox
                  v-model="show.deleted"
                  label="Удаленные"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="show.updated"
                  label="Обновленные"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="$emit('resetFilter')"
                fab
                icon
                small
                ><v-icon>mdi-filter-off</v-icon></v-btn
              >
            </template>
            <span>Сбросить фильтры</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// import customDatePicker from "@/components/common/CustomDatePicker";
import rangeDatePicker from "@/components/common/RangeDatePicker";
import statusChip from "@/components/common/statusChip";
import { mapActions, mapGetters, mapState } from "vuex";
import requestControl from "@/mixins/requestControl";

import { GET_ORDER_STATUSES } from "../../../../store/const/orders";
export default {
  mixins: [requestControl],
  components: {
    // customDatePicker,
    statusChip,
    rangeDatePicker
  },
  data() {
    return {
      show: {
        statuses: true,
        account: true,
        manager: true,
        trader: true,
        deleted: false,
        prioritet: false,
        done: false,
        updated: false,
        created: false
      }
    };
  },
  async created() {
    if (this.statusArray.length === 0) {
      await this.getStatuses();
    }
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    priorities: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    },
    m: {
      type: Array,
      required: true
    },
    a: {
      type: Array,
      required: true
    },
    t: {
      type: Array,
      required: true
    },
    created_from: {
      type: [Date, String, Number],
      required: true
    },
    created_to: {
      type: [Date, String, Number],
      required: true
    },
    updated_from: {
      type: [Date, String, Number],
      required: true
    },
    updated_to: {
      type: [Date, String, Number],
      required: true
    },
    deleted_from: {
      type: [Date, String, Number],
      required: true
    },
    deleted_to: {
      type: [Date, String, Number],
      required: true
    },
    done_from: {
      type: [Date, String, Number],
      required: true
    },
    done_to: {
      type: [Date, String, Number],
      required: true
    }
  },
  computed: {
    ...mapGetters("Users", {
      accounts: "getAccountUsers",
      traders: "getTraderUsers",
      managers: "getAllClientUsers"
    }),
    ...mapState("Orders", { prioritiesArr: "priorities" }),

    isStatusesLoading() {
      return this.loading(GET_ORDER_STATUSES) === "loading";
    },
    ...mapGetters("Orders", {
      statusArray: "getStatuses"
    }),
    ...mapGetters("Auth", {
      currentUserRole: "myRole"
    })
  },
  methods: {
    ...mapActions("Orders", {
      getStatuses: GET_ORDER_STATUSES
    })
  }
};
</script>

<style lang="scss">
.v-card__outer-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  .col-md-auto {
    flex-shrink: 1;
  }
}
</style>
