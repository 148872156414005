<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col class="d-flex pa-1 justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!agency.deleted_at"
            class="mr-1"
            @click.stop="$emit('clientDialog')"
            v-bind="attrs"
            v-on="on"
            fab
            icon
          >
            <v-icon dark>
              mdi-account-plus
            </v-icon></v-btn
          >
        </template>
        <span>Добавить клиента к этому агентству</span>
      </v-tooltip>
      <v-tooltip top>
        <template delay="20" v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!agency.deleted_at"
            class="mr-1"
            @click.stop="$emit('agencyDialog')"
            v-bind="attrs"
            v-on="on"
            fab
            icon
          >
            <v-icon dark>
              mdi-lead-pencil
            </v-icon></v-btn
          >
        </template>
        <span>Редактировать агентство</span>
      </v-tooltip>
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!agency.deleted_at"
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            @click.stop="removeAgency(agency)"
            fab
            
            icon
          >
            <v-icon>{{
              agency.deleted_at ? "mdi-delete-restore" : "mdi-delete"
            }}</v-icon></v-btn
          >
        </template>
        <span>{{
          agency.deleted_at ? "Восстановить агентство" : "Удалить агентство"
        }}</span>
      </v-tooltip> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { GET_AGENCIES, DELETE_AGENCY } from "@/store/const/agencies";
import requestControl from "@/mixins/requestControl";
export default {
  props: {
    agency: {
      type: Object,
      required: true
    }
  },
  mixins: [requestControl],
  methods: {
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES,
      deleteAgency: DELETE_AGENCY
    }),

    async removeAgency(agency) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.deleteAgency(agency);
        if (this.checkRequestOnError(DELETE_AGENCY)) {
          this.$notify({
            type: "succ",
            text: "Агентство успешно удалено"
          });
          this.fetchAgencies();
        }
      }
    }
  }
};
</script>

<style></style>
