<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="150"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-1"
        v-bind="attrs"
        rounded
        v-on="on"
        small
        outlined
        color="primary"
        ><span class="black--text"
          >+ {{ data[mainProp].length - 1 }}</span
        ></v-btn
      >
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ data.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item v-for="item in data[mainProp]" :key="item.id">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="menu = false">
          Save
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    mainProp: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menu: false
    };
  }
};
</script>

<style></style>
