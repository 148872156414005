var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('span',{attrs:{"id":"agencies"}},[_c('v-data-table',{staticClass:"elevation-0 border-top-0",class:{ 'minified-header__active': _vm.expandedAgencies.length === 1 },attrs:{"headers":_vm.defaultHeaders,"items":_vm.expandedAgencies.length === 1 ? _vm.expandedAgencies : _vm.agencies,"single-expand":true,"hide-default-footer":_vm.expandedAgencies.length === 1,"expanded":_vm.expandedAgencies,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expandedAgencies=$event},"item-expanded":_vm.getClientByAgencyId},scopedSlots:_vm._u([{key:"header.option",fn:function(ref){return [(_vm.myRole === 'root')?_c('div',{staticClass:"d-flex align-center justify-end",on:{"click":_vm.openAgencyCreateDialog}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Добавить агентство")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var agency = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',{staticClass:"expanded-row",on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',[_c('entity-table-info',{attrs:{"value":agency,"type":"agency"}})],1),(agency.charge || agency.charge === 0)?_c('td',[_c('span',[_vm._v(_vm._s(((agency.charge) + "%")))])]):_vm._e(),_c('td',[_c('agency-actions',{attrs:{"agency":agency},on:{"agencyDialog":function($event){return _vm.openEditAgencyDialog(agency)},"clientDialog":function($event){return _vm.openClientCreateDialog(agency)}}})],1),_c('td',[_c('v-icon',{staticClass:"expanded",class:{ rotate: isExpanded }},[_vm._v("mdi-chevron-down")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-0 bg-gray",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 border-top-0",class:{
              'current-entity__active': _vm.viewedEntity === 'client',
              'minified-header__active': _vm.expandedClients.length === 1
            },attrs:{"headers":_vm.defaultHeaders,"loading":_vm.isClientLoading,"items":_vm.expandedClients.length === 1 ? _vm.expandedClients : _vm.clients,"hide-default-footer":_vm.expandedClients.length === 1,"single-expand":true,"item-key":"name","show-expand":"","expanded":_vm.expandedClients},on:{"update:expanded":function($event){_vm.expandedClients=$event},"item-expanded":_vm.getBrandByClientId},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var client = ref.item;
            var expand = ref.expand;
            var isExpanded = ref.isExpanded;
return [_c('tr',{staticClass:"expanded-row",on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',[_c('entity-table-info',{attrs:{"value":client,"type":"client"}})],1),(client.charge || client.charge === 0)?_c('td',[_vm._v(" "+_vm._s(((client.charge) + "%"))+" ")]):_vm._e(),_c('td',[_c('client-actions',{attrs:{"client":client,"parent":_vm.expandedAgencies[0]},on:{"clientDialog":function($event){return _vm.openClientEditDialog(client, _vm.expandedAgencies)},"brandDialog":function($event){return _vm.openBrandCreateDialog(client)}}})],1),_c('td',[_c('v-icon',{staticClass:"expanded",class:{ rotate: isExpanded }},[_vm._v("mdi-chevron-down")])],1)])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
return [_c('td',{staticClass:"pa-0 bg-gray",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 border-top-0",class:{
                    'current-entity__active': _vm.viewedEntity === 'brand',
                    'minified-header__active': _vm.expandedBrands.length === 1
                  },attrs:{"headers":_vm.defaultHeaders,"items":_vm.expandedBrands.length === 1 ? _vm.expandedBrands : _vm.brands,"loading":_vm.isBrandLoading,"hide-default-footer":_vm.expandedBrands.length === 1,"single-expand":true,"item-key":"name","show-expand":"","expanded":_vm.expandedBrands},on:{"update:expanded":function($event){_vm.expandedBrands=$event},"item-expanded":_vm.getOrderByBrandId},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var brand = ref.item;
                  var expand = ref.expand;
                  var isExpanded = ref.isExpanded;
return [_c('tr',{staticClass:"expanded-row",on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',[_c('entity-table-info',{attrs:{"value":brand,"type":"brand"}})],1),(brand.charge || brand.charge === 0)?_c('td',[_vm._v(" "+_vm._s(((brand.charge) + "%"))+" ")]):_vm._e(),_c('td',[_c('brand-actions',{attrs:{"brand":brand,"parent":_vm.expandedClients[0]},on:{"reservationDialog":function($event){return _vm.openBrandEditDialog(brand)},"brandDialog":function($event){return _vm.openBrandEditDialog(brand, _vm.expandedClients[0])}}})],1),_c('td',[_c('v-icon',{staticClass:"expanded",class:{ rotate: isExpanded }},[_vm._v("mdi-chevron-down")])],1)])]}},{key:"header.option",fn:function(ref){return undefined}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var brand = ref.item;
return [_c('td',{staticClass:"pa-0 bg-gray",attrs:{"colspan":headers.length}},[_c('div',[_c('orders-table',{ref:"orderTable",class:{
                            'current-entity__active': _vm.viewedEntity === 'order'
                          },attrs:{"is-nested":""},on:{"updateAction":function($event){return _vm.getOrderByBrandId({ item: brand })},"onCurrentBrandPopupOpen":_vm.currentBrandPopupOpenHandler}})],1)])]}}],null,true)})],1)]}}],null,true)})],1)]}}],null,true)}),_c('agency-popup',{attrs:{"is-new":_vm.isNewEntity,"entity":_vm.currentEntity},model:{value:(_vm.agencyDialogState),callback:function ($$v) {_vm.agencyDialogState=$$v},expression:"agencyDialogState"}}),_c('client-popup',{attrs:{"is-new":_vm.isNewEntity,"entity":_vm.currentEntity,"parent-id":_vm.parentId},model:{value:(_vm.clientDialogState),callback:function ($$v) {_vm.clientDialogState=$$v},expression:"clientDialogState"}}),_c('brand-popup',{attrs:{"is-new":_vm.isNewEntity,"entity":_vm.currentEntity,"parent-id":_vm.parentId},model:{value:(_vm.brandDialogState),callback:function ($$v) {_vm.brandDialogState=$$v},expression:"brandDialogState"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }