var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex pa-1 justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.brand.deleted_at),expression:"!brand.deleted_at"}],staticClass:"mr-1",attrs:{"to":{
            name: 'newReservation',
            params: { id: _vm.brand.id },
            query: {
              brand: _vm.brand.name
            }
          },"fab":"","icon":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-shield-plus ")])],1)]}}])},[_c('span',[_vm._v("Создать бронь этого бренда")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.brand.deleted_at),expression:"!brand.deleted_at"}],staticClass:"mr-1",attrs:{"fab":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('brandDialog')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-lead-pencil ")])],1)]}}])},[_c('span',[_vm._v("Редактировать бренд")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }