<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="702"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <v-btn rounded outlined v-bind="attrs" v-on="on">
        {{ buttonText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span>{{
          isNew ? "Добавление клиента" : "Редактирование клиента"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="isNew ? createNewClient() : updateCurrentClient()"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="form.name"
                :rules="rule.regularField"
                label="Название *"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="currentUserRole === 'root'" cols="12">
              <v-text-field
                type="number"
                v-model.number="form.charge"
                label="Charge *"
                :rules="[...rule.required, ...rule.minZero]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <small>* Помечены обязательные поля</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded text @click="closeDialog()">
          Отмена
        </v-btn>
        <v-btn
          :loading="isLoading"
          v-if="isNew"
          rounded
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="createNewClient()"
        >
          Создать
        </v-btn>
        <v-btn
          :loading="isLoading"
          v-else
          rounded
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="updateCurrentClient()"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { CREATE_CLIENT, UPDATE_CLIENT } from "@/store/const/clients";
import { mapActions, mapGetters } from "vuex";
import { GET_CLIENTS_BY_AGENCY } from "../../../store/const/clients";

let defaultForm = {
  name: "",
  charge: 0
};

export default {
  mixins: [validationRules, requestControl],
  props: {
    parentId: {
      type: Number,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    },
    entity: {
      type: Object,
      required: false,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null
    };
  },
  computed: {
    ...mapGetters("Auth", ["getUser"]),
    isLoading() {
      return (
        this.loading(CREATE_CLIENT) === "loading" ||
        this.loading(UPDATE_CLIENT) === "loading"
      );
    },
    currentUserRole() {
      const {
        type: { id }
      } = this.getUser;
      return id.toLowerCase();
    }
  },
  watch: {
    entity(newValue) {
      // выбирает только те элементы обьекта которые в последствии будут использоваться
      if (newValue) {
        let form = {};
        for (const key in defaultForm) {
          if (Object.hasOwnProperty.call(defaultForm, key)) {
            form[key] = newValue[key];
          }
        }

        this.form = Object.assign({}, form);
      } else {
        this.form = Object.assign({}, defaultForm);
      }
    }
  },
  methods: {
    ...mapActions("Clients", {
      createClient: CREATE_CLIENT,
      getClientsByAgency: GET_CLIENTS_BY_AGENCY,
      updateClient: UPDATE_CLIENT
    }),

    async createNewClient() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        await this.createClient({ ...this.form, agency: this.parentId });
        if (this.checkRequestOnError(CREATE_CLIENT)) {
          this.$notify({
            type: "succ",
            text: "Клиент успешно создан"
          });
          await this.getClientsByAgency(this.parentId);
          this.closeDialog();
        }
      }
    },
    async updateCurrentClient() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        await this.updateClient({
          id: this.entity.id,
          data: { ...this.form, agency: this.parentId }
        });
        if (this.checkRequestOnError(UPDATE_CLIENT)) {
          this.$notify({
            type: "succ",
            text: "Клиент успешно обновлен"
          });
          this.getClientsByAgency(this.parentId);
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      this.form = Object.assign({}, defaultForm);
      this.$refs.form.resetValidation();
      // this.$refs.form.resetVal
    }
  }
};
</script>
