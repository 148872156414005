var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"d-flex align-center justify-start",class:{ 'flex-column': _vm.orintation }},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.order.deleted_at),expression:"!order.deleted_at"}],staticClass:"ma-1",attrs:{"small":"","fab":"","icon":!_vm.coloredButton},on:{"click":function($event){return _vm.$emit('openPopup')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-box-multiple")])],1)]}}])},[_c('span',[_vm._v("Назначить ответственных брони")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.order.deleted_at),expression:"!order.deleted_at"}],staticClass:"ma-1",attrs:{"small":"","color":_vm.getActionVerb(_vm.order, 'keepers') === 'remove' ? 'cyan darken-3' : '',"fab":"","icon":!_vm.coloredButton},on:{"click":function($event){return _vm.toggleKeeperStatus(_vm.order)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-hard-hat")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getActionVerb(_vm.order, "keepers") === "remove" ? "Перестать быть ответственным" : "Стать ответственным"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{
          name: 'newReservation',
          params: { id: _vm.order.brand.id },
          query: { copyOrder: _vm.order.id }
        },"small":"","icon":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Скопировать настройки")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","fab":"","icon":!_vm.coloredButton},on:{"click":function($event){_vm.order.deleted_at ? _vm.restoreOrder(_vm.order) : _vm.removeOrder(_vm.order)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.order.deleted_at ? "mdi-delete-restore" : "mdi-delete"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.order.deleted_at ? "Восстановить" : "Удалить"))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }