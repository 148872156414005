<template>
  <v-container
    class=" flex-nowrap flex-column"
    fluid="fluid"
    style="align-items: normal"
  >
    <view-toolbar
      v-model="search"
      :alerts="alerts"
      :alerts-list-active="alertsListActive"
      @onToggleAlertsList="toggleAlertsListHandler"
      @onClickOutside="onClickOutsideHandler"
      @alertWasRead="onAlertWasReadHandler"
      title="Брони"
    >
      <template #buttons>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="showTrashedEntities = !showTrashedEntities"
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{
                showTrashedEntities ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            showTrashedEntities
              ? "Скрыть удаленные сущности"
              : "Показать удаленные сущности"
          }}</span>
        </v-tooltip> -->

        <!-- <agency-popup
          button-text="Добавить агентство"
          :is-new="true"
          v-model="dialogState"
        >
        </agency-popup
      > -->
      </template>
    </view-toolbar>
    <order-filter
      :disabled="search.length > 2"
      v-bind.sync="filters"
      @resetFilter="resetFilter"
      @callRequest="debounceFilter"
    ></order-filter>
    <keep-alive>
      <component
        @updateAction="callUpdate"
        :is="viewComponent"
        :show-trashed="showTrashedEntities"
        @onCurrentBrandPopupOpen="onCurrentBrandClickHandler"
      ></component>
    </keep-alive>
  </v-container>
</template>

<script>
/* eslint-disable */
import viewToolbar from "@/components/common/ViewToolbar";
import agencyPopup from "@/components/management/agencies/Popup.vue";
import agencyExpansionPanel from "@/components/views/Reservation/components/AgencyExpansionPanel.vue";
import orderList from "@/components/views/Reservation/components/OrderList.vue";
import orderTable from "@/components/views/Reservation/components/OrderTableView.vue";
import orderFilter from "@/components/views/Reservation/components/OrderFilter.vue";
import { debounce } from "../../../plugins/helpers";
import {
  GET_ORDERS,
  GET_ORDER_PRIORITIES,
  SEARCH_ORDERS
} from "../../../store/const/orders";
import { mapActions, mapGetters } from "vuex";
import { GET_USERS, GET_USERS_TO_KEEPERS } from "../../../store/const/users";
import { GET_AGENCIES } from "../../../store/const/agencies";
import requestControl from "../../../mixins/requestControl";
import dbg from "../../../plugins/dbg";
import axios from "axios";

const defaultFilter = {
  statuses: [],
  m: [],
  a: [],
  t: [],
  priorities: [],
  created_from: "",
  created_to: "",
  updated_from: "",
  updated_to: "",
  deleted_from: "",
  deleted_to: "",
  done_from: "",
  done_to: ""
};

export default {
  components: {
    viewToolbar,
    agencyPopup,
    agencyExpansionPanel,
    orderList,
    orderTable,
    orderFilter
  },
  mixins: [requestControl],
  data() {
    return {
      showTrashed: false,
      dialogState: false, //agencyDialogState
      search: "",
      viewType: 1,
      viewComponent: "agencyExpansionPanel",
      filters: Object.assign({}, defaultFilter),
      filterWasMutate: false,
      alertsListActive: false,
      alerts: []
    };
  },

  watch: {
    showTrashedEntities() {
      if (this.viewComponent === "agencyExpansionPanel") {
        this.fetchAgencies();
      } else {
        this.getOrdersWithFilter();
      }
    },
    search(newVal) {
      this.changeViewComponent(newVal, this.viewType);
    },
    viewType(newVal) {
      this.changeViewComponent(this.search, newVal);
    }
  },
  created() {
    this.fetchUsersRules && this.fetchUsers();
    this.fetchAgencies();
    this.getPriorities();
    this.getAlerts();
    const headers = localStorage.getItem("orderHeaders");
    if (headers) {
      this.setDefaultOrderHeaders(JSON.parse(headers));
    }
    // this.filterOrders("");
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },
  computed: {
    ...mapGetters(["loading"]),
    ...mapGetters('Auth', ['getUser']),
    showTrashedEntities: {
      get() {
        return this.$store.state.Helpers.showTrashedEntities;
      },
      set(newVal) {
        this.$store.commit("Helpers/setShowTrashedEntities", newVal);
      }
    },
    currentUserRole() {
      const { type: { id } } = this.getUser;
      return id.toLowerCase();
    },
    fetchUsersRules() {
      return ['root', 'manager'].includes(this.currentUserRole);
    }
  },
  methods: {
    ...mapActions("Orders", {
      getPriorities: GET_ORDER_PRIORITIES
    }),
    callUpdate(event) {
      dbg("callUpdate", event);
      event === "search"
        ? this.searchOrders(this.search)
        : this.getOrdersWithFilter();
    },
    setDefaultOrderHeaders(newHeaders) {
      this.$store.commit("Orders/updateOrderHeaders", newHeaders);
    },
    resetFilter() {
      this.filters = JSON.parse(JSON.stringify(defaultFilter));
      this.filterWasMutate = false;
      this.changeViewComponent("", this.viewType);
    },
    allFilterFieldsIsEmpty() {
      for (const key in this.filters) {
        if (Object.hasOwnProperty.call(this.filters, key)) {
          const element = this.filters[key];
          if (element !== undefined && element.length != 0) {
            return false;
          }
        }
      }
      return true;
    },
    async getOrdersWithFilter() {
      if (this.allFilterFieldsIsEmpty()) {
        this.filterWasMutate = false;
        this.changeViewComponent("", this.viewType);
        return void 0;
      }
      if (this.cancelFunc(GET_ORDERS)) {
        this.cancelFunc(GET_ORDERS).cancel("caught cancel");
      }
      this.filterWasMutate = true;
      const queryString = this.buildQueryFilterString();
      this.filterOrders(queryString);
      this.changeViewComponent("", this.viewType);
    },
    buildQueryFilterString() {
      let queryString = "";
      for (const key in this.filters) {
        if (Object.hasOwnProperty.call(this.filters, key)) {
          let value = this.filters[key];
          const valueIsArray = Array.isArray(value);
          if (value.length != 0) {
            if (valueIsArray) {
              value = value.map(val => val.id ?? val.key ?? val);
              for (const index in value) {
                const filterElement = value[index];
                queryString += `${key}[]=${filterElement}&`;
              }
            } else {
              queryString += `${key}=${value}&`;
            }
          }
        }
      }
      return queryString;
    },
    changeViewComponent(search, viewType) {
      if (search.length > 2 || this.filterWasMutate == true) {
        if (viewType === 0) {
          this.viewComponent = "orderList";
        } else {
          this.viewComponent = "orderTable";
        }

        if (search.length > 2) {
          this.filters = Object.assign({}, defaultFilter);
          this.filterWasMutate = false;
          this.debounceSearch(search);
          this.$router.replace({ query: { search: search } }).catch(() => {});
        } else {
          this.$router.replace({ query: {} }).catch(() => {});
        }
      } else {
        this.viewComponent = "agencyExpansionPanel";
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },
    ...mapActions("Orders", {
      searchOrders: SEARCH_ORDERS,
      filterOrders: GET_ORDERS
    }),
    ...mapActions("Users", {
      fetchUsers: GET_USERS,
      fetchUsersToKeepers: GET_USERS_TO_KEEPERS
    }),
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),
    debounceFilter: debounce(function() {
      this.search = "";
      this.getOrdersWithFilter();
    }, 228),
    debounceSearch: debounce(function(searchKey) {
      this.searchOrders(searchKey);
    }, 420),
    async onCurrentBrandClickHandler(brand) {
      const { id } = brand;
      await this.fetchUsersToKeepers({ id });
    },
    toggleAlertsListHandler() {
      this.alertsListActive = !this.alertsListActive;
    },
    onClickOutsideHandler() {
      this.alertsListActive = false;
    },
    sortByCreatedAt(a, b) {
      if (a.created < b.created) return 1;
      else if (a.created > b.created) return -1;
      else return 0;
    },
    async getAlerts() {
      try {
        const response = await axios.get(this.$baseURL + 'alerts', {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("userToken")
          }
        });
        this.alerts = response.data.data.sort(this.sortByCreatedAt);
      } catch (e) {
        console.log(e)
      }
    },
    async onAlertWasReadHandler(alertId) {
      try {
        const response = await axios.patch(`${this.$baseURL}alerts/${alertId}/mark-as-read`, {}, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("userToken")
          }
        });
        this.alerts.find(alert => alert.id === alertId).read = true;
      } catch (e) {
        dbg(e)
      }
    }
  }
};
</script>
