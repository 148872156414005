<template>
  <span
    class="d-flex align-center justify-start"
    :class="{ 'flex-column': orintation }"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!order.deleted_at"
          class="ma-1"
          @click="$emit('openPopup')"
          small
          fab
          :icon="!coloredButton"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-btn>
      </template>
      <span>Назначить ответственных брони</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!order.deleted_at"
          class="ma-1"
          @click="toggleKeeperStatus(order)"
          small
          :color="
            getActionVerb(order, 'keepers') === 'remove' ? 'cyan darken-3' : ''
          "
          fab
          :icon="!coloredButton"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-btn>
      </template>
      <span>{{
        getActionVerb(order, "keepers") === "remove"
          ? "Перестать быть ответственным"
          : "Стать ответственным"
      }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :to="{
            name: 'newReservation',
            params: { id: order.brand.id },
            query: { copyOrder: order.id }
          }"
          small
          icon
          fab
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>Скопировать настройки</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          @click="order.deleted_at ? restoreOrder(order) : removeOrder(order)"
          v-bind="attrs"
          v-on="on"
          small
          fab
          :icon="!coloredButton"
        >
          <v-icon>{{
            order.deleted_at ? "mdi-delete-restore" : "mdi-delete"
          }}</v-icon>
        </v-btn>
      </template>
      <span>{{ order.deleted_at ? "Восстановить" : "Удалить" }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_AGENCIES } from "@/store/const/agencies";

import requestControl from "@/mixins/requestControl";
import {
  SEARCH_ORDERS,
  DELETE_ORDER,
  EDIT_KEEPER_STATUS_FOR_ORDER,
  GET_ORDERS_BY_BRAND,
  EDIT_WATCHER_STATUS_FOR_ORDER,
  REPAIR_ORDER
} from "../../../store/const/orders";
import dbg from "@/plugins/dbg";

export default {
  mixins: [requestControl],
  props: {
    order: {
      type: Object,
      required: true
    },
    coloredButton: {
      type: Boolean,
      required: false,
      default: false
    },
    parent: {
      type: Object,
      required: false,
      default: () => {}
    },
    orintation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentEntity: {},
      dialogState: false,
      footerProps: {
        "items-per-page-options": [5, 10, 20, 50, -1],
        "items-per-page-text": "Броней на странице:"
      },
      headers: [
        {
          text: "Название",
          align: "start",
          value: "name"
          // width: 50,
        },
        {
          text: "Статус",
          align: "center",
          value: "status"
        },
        { text: "Опции", align: "start", value: "options", sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters("Auth", ["getUserId"])
  },
  methods: {
    ...mapActions("Agencies", {
      fetchAgencies: GET_AGENCIES
    }),

    ...mapActions("Orders", {
      deleteOrder: DELETE_ORDER,
      repairOrder: REPAIR_ORDER,
      editKeeperStatus: EDIT_KEEPER_STATUS_FOR_ORDER,
      editWatcherStatus: EDIT_WATCHER_STATUS_FOR_ORDER,
      searchOrders: SEARCH_ORDERS,
      getOrdersByBrand: GET_ORDERS_BY_BRAND
    }),
    async restoreOrder(order) {
      await this.repairOrder(order);
      if (this.checkRequestOnError(DELETE_ORDER)) {
        this.$notify({
          type: "succ",
          text: "Бронь успешно восстановлена"
        });
        this.fetchData();
      }
    },
    async removeOrder(order) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.deleteOrder(order);
        if (this.checkRequestOnError(DELETE_ORDER)) {
          this.$notify({
            type: "succ",
            text: "Бронь успешно удалена"
          });
          this.fetchData();
        }
      }
    },
    // return action "Change" or "Remove"
    getActionVerb(order, prop) {
      return order[prop]?.every(keeper => keeper.id != this.getUserId)
        ? "change"
        : "remove";
    },
    async toggleWatcherStatus(order) {
      const verb = this.getActionVerb(order, "watchers");
      await this.editWatcherStatus({
        id: order.id,
        verb,
        user: this.getUserId
      });
      if (this.checkRequestOnError(EDIT_WATCHER_STATUS_FOR_ORDER)) {
        const text =
          verb === "change"
            ? "Вы успешно добавили себя наблюдателем за эту бронью"
            : "Вы перестали быть наблюдателем за этой бронью";
        this.$notify({
          type: "succ",
          text
        });
        this.fetchData();
      }
    },
    async toggleKeeperStatus(order) {
      const verb = this.getActionVerb(order, "keepers");
      try {
        await this.editKeeperStatus({
          id: order.id,
          verb,
          user: this.getUserId
        });
        this.fetchData();
      } catch (err) {
        dbg(err);
      }

      if (this.checkRequestOnError(EDIT_KEEPER_STATUS_FOR_ORDER)) {
        const text =
          verb === "change"
            ? "Вы стали ответственным брони"
            : "Вы перестали быть ответственным брони";
        this.$notify({
          type: "succ",
          text
        });
        this.fetchData();
      }
    },

    fetchData() {
      this.$emit("updateAction");
    }
  }
};
</script>

<style></style>
